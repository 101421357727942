import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

function behaviourSocialisationTraining({ data }) {
  const d = data.allContentfulServicesItem.nodes[0]
  const title = data.allContentfulServicesItem.nodes[0].name
  const desc = data.allContentfulServicesItem.nodes[0].seoDescription

  return (
    <Layout>
      <SEO title={title} description={desc} />
      <h1 className="mb-4">{title}</h1>
      <div className="mx-6 max-w-6xl xl:mx-auto my-6 p-6 bg-white md:flex rounded-lg shadow-light btn-pb">
        <img
          className="md:h-64 md:w-64 md:min-h-64 md:min-w-64 h-48 w-48 min-h-48 min-w-48 rounded-full shadow-lg mx-auto md:self-start md:mt-4"
          src={d.image.file.url}
          alt={title}
        />
        <div className="px-6 text-black text-justify min-h-full md:text-left">
          <div
            className="services"
            dangerouslySetInnerHTML={{
              __html: d.article.childMarkdownRemark.html,
            }}
          />
          <Link to="/contact" className="services">
            <button
              className="button shadow-xl mx-auto"
              onClick={() => "/contact"}
              title="Book Now"
            >
              Book Now
            </button>
          </Link>
          <div className="mx-auto text-center mt-4">
            <Link to={"/services/"} title="Go back to services">
              <span role="img" aria-label="close">
                ❌
              </span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulServicesItem(
      filter: { slug: { eq: "behaviour-socialisation-training" } }
    ) {
      nodes {
        id
        name
        seoDescription
        slug
        image {
          file {
            url
          }
        }
        article {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default behaviourSocialisationTraining
